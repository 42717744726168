import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyC25AUmsLcwLV5ENUSjbXvz7rP730M5NIY",
    authDomain: "shea-portfolio-3c735.firebaseapp.com",
    databaseURL: "https://shea-portfolio-3c735.firebaseio.com",
    projectId: "shea-portfolio-3c735",
    storageBucket: "shea-portfolio-3c735.appspot.com",
    messagingSenderId: "1036316198077",
    appId: "1:1036316198077:web:4e8fe1d1063e3adc63e3d4",
    measurementId: "G-PWKTGHLDX2"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export const firestore = firebase.firestore();