import React,{useState} from "react";
import FormInput from '../form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import {ContactFormContainer, Title, ButtonsContainer} from './contact-form.styles';
import {ExitIcon} from '../floating-info/floating-info.styles';
import {firestore} from '../../firebase/firebase.utils';



const ContactForm = ({handleClose}) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [details, setDetails] = useState('');

    function handleSubmit(event) {
            event.preventDefault();
            
            try {
                alert(
                    `Thank you  ${name}! Your Email has been sent I will be in contact asap`
                );

                sendEmail();
                setDetails('');
                setMobile('');
                setName('');
                setEmail('');
            } catch(e) {
                console.log(e);
            }
    
    };

    function sendEmail() {
    firestore.collection('emails/').add({
            name: name,
            email: email,
            mobile: mobile,
            details: details,
            time: new Date(),
        }).catch(e => {
            console.log(e);
        });

      
    }

    return (
        <ContactFormContainer>
        <Title>Get in touch</Title>
            <span>Please submit your details and I will be in contact asap</span>
            <ExitIcon onClick={handleClose} />
            <form onSubmit={handleSubmit}>
                <FormInput
                    name='name' 
                    type ='name' 
                    handleChange={e => setName(e.target.value)} 
                    value={name}
                    label='Name'
                    required 
                />
                <FormInput
                    name='email' 
                    type ='email' 
                    handleChange={e => setEmail(e.target.value)} 
                    value={email}
                    label='Email'
                    required 
                />
                <FormInput
                    name='mobile' 
                    type='mobile' 
                    handleChange={e => setMobile(e.target.value)} 
                    value={mobile}
                    label='Mobile'
                    required 
                />
                <FormInput
                    name='details'
                    type='details'
                    handleChange={e => setDetails(e.target.value)} 
                    value={details}
                    label='Query'
                    required 
                />
                <ButtonsContainer>
                    <CustomButton type='submit' value = 'submit'> Submit </CustomButton>
                </ButtonsContainer>
            </form>
        </ContactFormContainer>
    )
}

export default ContactForm; 
