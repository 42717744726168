
  
import styled, {keyframes} from 'styled-components';
import LinkIcon from '../../assets/link.svg';
import CloseIcon from '../../assets/close.svg';
import Img from "gatsby-image";



export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    position:absolute;
    width: 92vw;
    height: 600px;
    background: #ffffff;
    text-align:center;
    max-width: 1200px;
    border: double;
    top: 120.5vh;
    z-index: 1;
    @media screen and (max-width: 800px) {
     height: 480px;
     top: 12.5vh;
     right: 4%;
    }
`;

export const VideoComponent = styled.video`
    width: 100%;
    height: 300px;
`;

export const InfoImage = styled(Img)`
    width: 100%;
`;

export const TextInfo = styled.p`
    text-align: center;
    width: 75%;
    min-height: 52px;
    @media screen and (max-width: 800px) {
        min-height: 180px;
        max-width: 247px;
        width: auto;
        padding-bottom: 29px;
    }
`;

export const TextContainer = styled.div`
     display: flex;
     flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: double #00000036;
`;

const leftBracketAnimation = keyframes`
   40% {
        transform: scale(1);
    }
    55% {
        stroke: #ff6633;
        transform: translate(5px);        
    }
    70% {
        transform: translate(30px);
    }
    100% {
        transform: translate(30px);
        stroke: #cc0033;
    }  
`;

const rightBracketAnimation = keyframes`
      40% {
        transform: scale(1);
    }
    55% {
        stroke: #ff6633;
        transform: translate(-5px);
    }
    70% {
        transform: translate(-30px);
    }
    100% {
        transform: translate(-30px);
        stroke: #cc0033;
    }
`;


const cDashAnimation = keyframes`
    80% {
        transform: rotate(-70deg) rotateX(70deg);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(-90deg) rotateX(90deg);
    }
`;

const arrowAnim = keyframes`
    30% {
        stroke-opacity: 0.25;
        ${'' /* transform: rotateY(5); */}
    }
    50% {
        
        ${'' /* transform: rotateY(5); */}
        stroke-opacity: 0.75;
    }
    
    80% {
        stroke-opacity: 1;
        ${'' /* transform: rotateY(100); */}
        stroke: red;
    }
    100% {
        stroke-opacity: 1;
        ${'' /* transform: rotateY(100); */}
        stroke: red;
    }
`;

export const InfoLink = styled(LinkIcon)`
    width: 90px;
    height: 90px;
    position: absolute;
    cursor: pointer;
    bottom: 12%;
    transition: all 2.5s ease-in-out;

    @media screen and (max-width: 800px) {
        top: 411px;
        left: -6px;
        width: 70px
    }
    &#link-icon {
   
    &#link-icon:hover .arrow-left {
        animation: ${arrowAnim} 2s ease forwards;
    }
    
    &#link-icon:hover .arrow-right {
        animation: ${arrowAnim} 2s ease forwards;
    }
        .bubble {
            opacity: 0;
        }
      
        &#link-icon .arrow-right, .arrow-left {
            stroke-opacity:0;
            stroke: red;
            :hover {
                animation: ${arrowAnim} 2s ease forwards;
            }
        }
        .center-dash {
            transform-origin: center;
        }
        .right-bracket, .left-bracket, .center-dash {
            stroke: #cc0033;
        }
        &#link-icon:hover .right-bracket {
           /* transition: all 1s ease-in-out;  */
            animation: ${leftBracketAnimation} 1s ease-out forwards;
        }
        
         &#link-icon:hover .left-bracket {
            animation: ${rightBracketAnimation} 1s ease-out forwards; 
            /* transition: all 1s ease-in-out;  */
        } 
        &#link-icon:hover .center-dash {
            animation: ${cDashAnimation} 0.5s ease-out forwards; 
        } 
    }
`;





const circleAnimation = keyframes`
    100% {
        opacity: 1;
        transform: scale(1.5), translate(25,25);
    }
`;

const dashAnimation = keyframes`
    40% {
        transform: scale(1.1), translate(25,25);
    }
    55% {
        stroke: #ff6633;
        transform: scale(1.1),translate(25,25);
        
    }
    70% {
        transform: scale(1.1),translate(25,25);
    }
    100% {
        stroke-dashoffset: 0;
        transform: scale(1.1),translate(25,25);
        stroke: #cc0033;
    }
`



export const ExitIcon = styled(CloseIcon)`
    width: 80px;
    height:80px;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    z-index: 1;
    @media screen and (max-width: 800px) {
        bottom: -5px;
    right: -5px;
    width: 60px;
    height: 60px;
    }
    
    &#exit-icon .left-dash .right-dash {
        opacity: 0.5;
        stroke-linecap:round;
		transform-origin: center;
		stroke-dasharray: 400;
		stroke-dashoffset: 400;
    }
    &#exit-icon:hover .left-dash {
        animation: ${dashAnimation} 0.8s ease-out forwards; 
    }
    &#exit-icon:hover .right-dash {
        animation: ${dashAnimation} 1s ease-out forwards; 
    }
    &#exit-icon .circle {
            opacity: 0;
            transform-origin: center;
    }
    &#exit-icon:hover .circle {
        animation: ${circleAnimation} 0.5s ease-out forwards;        
    }
`;

export const Title = styled.h1`
    font-weight: bold;
    padding-top: 30px;
    margin-left: 20px;
 
`;

