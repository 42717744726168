import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import ParallaxImage from '../../images/parallax-images/three.jpg';
import ContactForm from '../contact-form/contact-form.component';
import {animateScroll as scroll} from 'react-scroll';
import {ParallaxCom} from './header.styles';
import {
  ShowCaseHeader,
  ShowCaseHeaderContainer,
  HeaderTag,
  HeaderNav,
  HeaderNavItem,
  DownloadLink
} from "./header.styles"
import WithSpinner from "../with-spinner/with-spinner.component"

const HeaderTagWithSpinner = WithSpinner(HeaderTag)


const Header = ({ siteTitle }) => {

  const [isOpen, setOpen] = useState(false);




  function open() {
    setOpen(true);
    scroll.scrollTo(65)
  }
  
 function close () {
    setOpen(false);
    document.body.setAttribute('style', '')
    scroll.scrollTo(0)
  }


 
  
  return (
    <HeaderTagWithSpinner
      style={{
        marginBottom: `7rem`,
      }}
    >
      <div
        style={{
          background: `-o-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 47%, rgba(247,247,247,1) 100%)`,
          position: `sticky`,
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: `1.25rem`,
            padding: `15px`,
            borderBottom: "#3f3b3b47 solid thin",
          }}
        >
          <Link
            to="/"
            style={{
              color: "rgb(0 0 0 / 68%)",
              textDecoration: `none`,
              margin: `auto`,
              width: `50%`,
              padding: `10px`,
              fontWeight: 400,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
      <ParallaxCom
        bgImage = {  ParallaxImage }
        strength={400}
        bgImageStyle={{height: '160%'}}
      >
      </ParallaxCom>
 
      <ShowCaseHeaderContainer>
        <div>
          <ShowCaseHeader>Full Stack Developer</ShowCaseHeader>
        </div>
        <div>
          <HeaderNav>
            <HeaderNavItem onClick={isOpen ? () => close() : () => open()}>Contact</HeaderNavItem>
            <HeaderNavItem onClick={() =>  scroll.scrollTo(1800)}>Project Images</HeaderNavItem>
            <DownloadLink href={"https://drive.google.com/file/d/1so-YMfLh9bj3aReeU-MzWIOuIfD91EY1/view?usp=sharing" }>Resume</DownloadLink>         
          </HeaderNav>
        </div>
      </ShowCaseHeaderContainer>
      {isOpen ? 
      <ContactForm handleClose={() => close()} />
      : 
      null }
    </HeaderTagWithSpinner>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
