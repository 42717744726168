import styled from "styled-components"

import { Parallax } from "react-parallax"


export const HeaderTag = styled.header`
  
`;

export const PhotoCredTag = styled.p`
color: white;
opacity: 0.2;
background-color: lightslategrey;
border-radius: 6px;
width: fit-content;
padding: 2px;
font-size: small;
top: 0;
    right: 8px;
    position: absolute;
&:hover {
  opacity: 0.8;
}
`;
export const ParallaxCom = styled(Parallax)`
  

  height: 300px;

     @media screen and (min-width: 768px) {
      height: 73vh;
    }
`;


export const ShowCaseHeaderContainer = styled.div`
    display: flex;
    height: 200px;
    border-bottom: #f4f1f1 thick double;
    justify-content: center;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      height: 300px;
    }

`;

export const ShowCaseHeader = styled.h1`
    font-weight: 400;
    padding: 35px 0;
    text-align: center;
    font-size: 2rem;
`;

export const HeaderNav = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

`;

export const HeaderNavItem = styled.span`  
  cursor:pointer;
  color: black;
     font-size:medium;
  font-weight: 300;
     padding: 0 10px; 
  transition: color .25s ease-in-out;
  &:hover {
    color: #b12d2db5;
  }
  @media screen and (min-width: 768px) {
  font-size: x-large;
  padding: 0 40px;
    }
`; 

export const DownloadLink = styled.a`
  cursor:pointer;
  color: black;
     font-size:medium;
     padding: 0 10px; 
  font-weight: 300;
  transition: color .25s ease-in-out;
  text-decoration: none;
  &:hover {
    color: #b12d2db5;
  }
  @media screen and (min-width: 768px) {
  font-size: x-large;
  padding: 0 40px;
    }
  `;