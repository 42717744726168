import styled from 'styled-components';


export const ContactFormContainer = styled.div`
    width: 650px;
    display: flex;
    flex-direction: column;
    border: double;
    padding: 25px;
    background-color: white;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    position: absolute;
    left: 51%;
    top: 400px;
    transform: translate(-50%, -50%);
    z-index: 1;

    @media screen and (max-width: 800px) {
        width: 90vw;
        left: 50%;
		}
`;


export const Title = styled.h2` 
    margin: 10px 0;
`;


export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;